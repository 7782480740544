import App from 'next/app';

/** CSS **/
/* First we import our provider */
import { Fragment } from 'react';
import { appWithTranslation } from 'next-i18next';
import wdyr from '../src/utils/wdyr';
import { patchPreact } from '../src/utils/patch-preact';
import MainComponent from '../src/utils/MainComponent';
import config from '../env.config';

import '../src/app-cosmos';
import 'styles/main.scss'

if (config.currentEnv !== 'development') patchPreact();

class MyApp extends App {
  constructor(props) {
    super(props);
  }

  render() {
    const { Component, pageProps } = this.props;
    const { query = {} } = pageProps;

    return (
      <Fragment>
        <MainComponent
          Component={Component}
          query={query}
          pageProps={pageProps}
        />
      </Fragment>

    );
  }
}

export default appWithTranslation(MyApp);
